import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import AlertModal from '../AlertModal/AlertModal';
import LeagueSelector from '../LeagueSelector/LeagueSelector';
import Scoreboard from '../Scoreboard/Scoreboard';
import { ScheduleData, ModalData, GameData } from '../../types';
import './HistoricalScores.scss';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';

const DEFAULT_LEAGUE = process.env.REACT_APP_DEFAULT_LEAGUE || '2021-w-w';
const LOADING_MODAL_DATA: ModalData = {
  display: true,
  text: 'Loading Data',
  mode: 'loading',
};

/**
 * Get all schedule data for a given league slug
 */
export const LEAGUE_SCHEDULE_QUERY = gql`
  query LEAGUE_SCHEDULE_QUERY($leagueSlug: String!) {
    allLeagues(where: { slug: $leagueSlug }) {
      name
      currentWeek
      games {
        id
        sheet
        slug
        topTeam {
          id
          slug
          name
        }
        bottomTeam {
          id
          slug
          name
        }
        ends {
          number
          scoringTeam
          score
        }
        weekInteger
      }
    }
  }
`;

const getScoreboards = (weekNum: number, gameDataArray: GameData[]) => {
  return gameDataArray.map((game: GameData, index: Number) => {
    return (
      <div
        className="historical-scores__scoreboard"
        key={`historical-week-${weekNum}-game-${index}`}
      >
        <Scoreboard gameData={game} consistentWidth={true} />
      </div>
    );
  });
};

export const getSortedGamesByWeek = (scheduleData: ScheduleData) => {
  const gamesByWeek = scheduleData.games.reduce(
    (acc: GameData[][], game: GameData) => {
      const weekIndex = game.weekInteger - 1;
      if (!acc[weekIndex]) {
        acc[weekIndex] = [game];
      } else {
        acc[weekIndex].push(game);
      }

      return acc;
    },
    []
  );

  gamesByWeek.forEach((weekOfGames: GameData[]) => {
    weekOfGames.sort((a, b) => a.sheet - b.sheet);
  });

  return gamesByWeek;
};

/**
 *
 * @param scheduleData data returned from schedule api
 */
const getWeeks = (scheduleData: ScheduleData) => {
  const gamesByWeek = getSortedGamesByWeek(scheduleData);

  return gamesByWeek.map((games, idx) => {
    return (
      <div className="historical-scores__week" key={`historical-week--${idx}`}>
        <h3>Week {idx + 1}</h3>
        {getScoreboards(idx, games)}
      </div>
    );
  });
};

const HistoricalScores: React.FC = () => {
  const history = useHistory();
  const { leagueId = DEFAULT_LEAGUE }: { leagueId?: string } = useParams();
  const [selectedLeague, setSelectedLeague] = useState(leagueId);

  const { data, loading, error } = useQuery<{
    allLeagues: ScheduleData[];
  }>(LEAGUE_SCHEDULE_QUERY, {
    variables: {
      leagueSlug: selectedLeague,
    },
  });

  const onLeagueChange = (leagueId: string) => {
    history.push(`/historicalScores/${leagueId}`);
    setSelectedLeague(leagueId);
  };

  if (loading) {
    return (
      <AlertModal
        key="historical-scores-loading"
        modalData={LOADING_MODAL_DATA}
      ></AlertModal>
    );
  }

  if (error || !data) {
    return (
      <AlertModal
        key="historical-scores-error"
        modalData={{
          display: true,
          text: `Error loading schedule: ${error?.message}`,
          mode: 'gameSubmit',
        }}
      ></AlertModal>
    );
  }

  return (
    <div className="historical-scores-page">
      <LeagueSelector
        onChange={onLeagueChange}
        selectedLeague={selectedLeague}
        showAll={true}
      ></LeagueSelector>
      <section className="HistoricalScores">
        {getWeeks(data.allLeagues[0])}
      </section>
    </div>
  );
};

export default HistoricalScores;
