import React from 'react';
import { TeamStandings } from '../../types';

/**
 * Turn standings data into standings table rows
 * @param teamsArray
 * @return TSX
 */
const getStandingsRows = (teamsArray: TeamStandings[]) => {
  return teamsArray.map((team, index) => {
    const {
      name,
      gamesPlayed,
      wins,
      losses,
      draws,
      points,
      totalScored,
      // endsWon,
      highEnd,
    } = team;
    return (
      <tr key={`standings-row--${index}`}>
        <td className="standings-td standings-td--index">{index + 1}</td>
        <td className="standings-td standings-td--name">{name}</td>
        <td className="standings-td standings-td--gp">{gamesPlayed}</td>
        <td className="standings-td standings-td--wins">{wins}</td>
        <td className="standings-td standings-td--losses">{losses}</td>
        <td className="standings-td standings-td--draws">{draws}</td>
        <td className="standings-td standings-td--points">{points}</td>
        <td className="standings-td standings-td--total">{totalScored}</td>
        {/* <td className='standings-td standings-td--endsWon'>{endsWon}</td> */}
        <td className="standings-td standings-td--highEnd">{highEnd}</td>
      </tr>
    );
  });
};

/**
 *
 * @param prop
 */
const StandingsTable: React.FC<{ teams: TeamStandings[] }> = (prop) => {
  const { teams } = prop;

  const standingsRows = getStandingsRows(teams);

  return (
    <section className="standings-container">
      <table className="standings-table">
        <thead>
          <tr>
            <th></th>
            <th title="Team Name">Team</th>
            <th title="Games Played">GP</th>
            <th title="Wins">W</th>
            <th title="Losses">L</th>
            <th title="Ties">T</th>
            <th title="Points">Pts</th>
            <th title="Total Scored">Ttl</th>
            {/* <th title='Ends Won'>EW</th> */}
            <th title="Highest End">HE</th>
          </tr>
        </thead>
        <tbody>{standingsRows}</tbody>
      </table>
    </section>
  );
};

export default StandingsTable;
