import { useQuery } from '@apollo/client';
import React, { RefObject } from 'react';
import { GameData } from '../types';
import { GAMES_BY_TEAM_QUERY } from './TeamSchedule';

function GameOption({ game, teamId }: { game: GameData; teamId: string }) {
  const { id, week, topTeam, bottomTeam } = game;
  const { date } = week;
  const dateObj = new Date(date);

  return (
    <option value={id}>
      {dateObj.toLocaleDateString()} {dateObj.toLocaleTimeString()} vs.{' '}
      {topTeam.id === teamId ? bottomTeam.name : topTeam.name}
    </option>
  );
}

export default function GameSelectorByTeam({
  teamId,
  gameSelectRef,
}: {
  teamId: string;
  gameSelectRef: RefObject<HTMLSelectElement>;
}) {
  const { data, loading, error } = useQuery(GAMES_BY_TEAM_QUERY, {
    variables: {
      teamId: teamId,
    },
  });

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  const NOW = new Date();
  const { allGames }: { allGames: GameData[] } = data;
  const games: GameData[] = allGames.filter((game) => {
    return NOW.toISOString() < game.week.date;
  });

  return (
    <p>
      <span>Game: </span>
      <select ref={gameSelectRef}>
        {loading ? (
          <option>Loading...</option>
        ) : (
          games.map((game: GameData) => {
            return <GameOption key={game.id} game={game} teamId={teamId} />;
          })
        )}
      </select>
    </p>
  );
}
