import React, { useState } from 'react';

import GameSummary from '../GameSummary/GameSummary';
import { GameData, End, ModalData } from '../../types';
import AlertModal from '../AlertModal/AlertModal';
import ScoresEntryStyles from '../../styles/ScoresEntry';
import { TeamScoreboardStyles } from '../../styles/GlobalStyles';

const SCORE_VALUES: ('B' | number)[] = ['B', 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

/**
 *
 * @param gameData
 * @param isTop
 * @returns
 */
const getTeamEndsByScore = (gameData: GameData, isTop: boolean) => {
  const { ends } = gameData;
  let teamScore = 0;

  return ends.reduce((teamEnds: any, end, index) => {
    const { scoringTeam, score } = end;

    if (
      (isTop && scoringTeam === 'TOP') ||
      (!isTop && scoringTeam === 'BOTTOM')
    ) {
      const endNumber = index + 1;
      teamScore += score;

      if (teamScore === 0) {
        teamEnds.B = endNumber;
      } else if (teamScore % 10 === 0) {
        teamEnds[10] = endNumber;
      } else {
        teamEnds[teamScore % 10] = endNumber;
      }
    }

    return teamEnds;
  }, {});
};

const getScore = (gameData: GameData, isTop: boolean): number => {
  return gameData.ends.reduce((currentScore: number, end: End) => {
    const { scoringTeam, isBlank, score } = end;

    if (!isBlank) {
      if (
        (isTop && scoringTeam === 'TOP') ||
        (!isTop && scoringTeam === 'BOTTOM')
      ) {
        return currentScore + score;
      }
    }

    return currentScore;
  }, 0);
};

interface ScoresEntryEndsOpts {
  isTop: boolean;
  score: 'B' | number;
  index: number;
  addEnd: any;
  endsByScore: any;
}

const ScoresEntryEnds = (opts: ScoresEntryEndsOpts) => {
  const { isTop, score, index, addEnd, endsByScore } = opts;

  const scoreToValue = (score: number | 'B'): number => {
    if (score === 'B') {
      return 0;
    }

    return score;
  };

  const getClickHandler = (value: number, isTop: boolean) => {
    return (e: React.MouseEvent) => {
      addEnd(value, isTop);
    };
  };

  return (
    <div
      className={`score-entry ${
        isTop ? 'score-entry--top' : 'score-entry--bottom'
      }`}
      data-team={`${isTop ? 'TOP' : 'BOTTOM'}`}
      key={index}
      data-value={scoreToValue(score)}
      onClick={getClickHandler(scoreToValue(score), isTop)}
    >
      {endsByScore[score] || ''}
    </div>
  );
};

const ScoreLabels = (score: string | number, index: number) => {
  return (
    <div className="score-label" key={index}>
      {score}
    </div>
  );
};

const removeLastEnd = (gameData: GameData, setGameData: any): void => {
  const { ends } = gameData;
  setGameData({
    ...gameData,
    ends: ends.slice(0, -1),
  });
};

const clearEnds = (gameData: GameData, setGameData: any): void => {
  setGameData({
    ...gameData,
    ends: [],
  });
};

const swapTeams = (gameData: GameData, setGameData: any): void => {
  const { topTeam, bottomTeam } = gameData;
  setGameData({
    ...gameData,
    topTeam: bottomTeam,
    bottomTeam: topTeam,
  });
};

const submitScores = (gameData: GameData, setModalData: any): void => {
  setModalData({
    text: 'Please confirm the game score before submitting',
    display: true,
    gameData: gameData,
    mode: 'gameSubmit',
  });
};

const getEndScore = (currTeamScore: number, score: number): number => {
  let adjustedScore = score;
  if (currTeamScore > 10) {
    adjustedScore = Math.floor(currTeamScore / 10) * 10 + score;
  }

  if (adjustedScore < currTeamScore) {
    return (
      Math.ceil(currTeamScore / 10) * 10 + (adjustedScore % 10) - currTeamScore
    );
  }
  return adjustedScore - currTeamScore;
};

const ScoresEntry: React.FC<{
  game: GameData;
}> = (props) => {
  const [gameData, setGameData] = useState(props.game);

  const DEFAULT_MODAL_DATA: ModalData = {
    display: false,
    text: 'Loading Data',
    mode: 'loading',
  };

  const [modalData, setModalData] = useState(DEFAULT_MODAL_DATA);

  const topEndsByScore = getTeamEndsByScore(gameData, true);
  const bottomEndsByScore = getTeamEndsByScore(gameData, false);

  const topEnds: any = [];
  const bottomEnds: any = [];
  const scoreLabels: any = [];

  const addEnd = (score: number, isTopTeam: boolean) => {
    const { ends }: GameData = gameData;
    const currTeamScore = getScore(gameData, isTopTeam);

    if (score === 0 && currTeamScore > 0) {
      let currScoreLabel = currTeamScore % 10;
      if (currScoreLabel === 0) {
        currScoreLabel = 10;
      }

      const teamLabel = isTopTeam ? 'above' : 'below';

      const bErrorMessage =
        '"B" should only be used to demonstrate blank ends when a team has not scored yet.' +
        '\nIf you want to add a blank end, click the current score again.' +
        `\nIn this case, click the end label ${teamLabel} ${currScoreLabel}`;
      return alert(bErrorMessage);
    }

    const endScore = getEndScore(currTeamScore, score);

    const scoringTeam = isTopTeam ? 'TOP' : 'BOTTOM';
    const newEnd: End = {
      score: endScore,
      scoringTeam,
    };

    return setGameData({
      ...gameData,
      ends: [...ends, newEnd],
    });
  };

  SCORE_VALUES.forEach((score, index) => {
    topEnds.push(
      ScoresEntryEnds({
        isTop: true,
        score,
        index,
        addEnd,
        endsByScore: topEndsByScore,
      })
    );
    bottomEnds.push(
      ScoresEntryEnds({
        isTop: false,
        score,
        index,
        addEnd,
        endsByScore: bottomEndsByScore,
      })
    );
    scoreLabels.push(ScoreLabels(score, index));
  });

  const topName = (gameData.topTeam && gameData.topTeam.name) || '';
  const bottomName = (gameData.bottomTeam && gameData.bottomTeam.name) || '';

  return (
    <ScoresEntryStyles>
      {modalData.display && (
        <AlertModal
          modalData={modalData}
          onCancel={() => {
            setModalData({ ...modalData, display: false });
          }}
        ></AlertModal>
      )}
      <GameSummary
        topTeam={{
          name: topName,
          score: getScore(gameData, true),
        }}
        bottomTeam={{
          name: bottomName,
          score: getScore(gameData, false),
        }}
      ></GameSummary>
      <div className="button-group button-group--edit">
        <button
          className="button button--swap"
          onClick={() => {
            swapTeams(gameData, setGameData);
          }}
        >
          Swap Colors
        </button>
        <button
          className="button button--clear"
          onClick={() => {
            clearEnds(gameData, setGameData);
          }}
        >
          Clear Ends
        </button>
        <button
          className="button button--undo"
          onClick={() => {
            removeLastEnd(gameData, setGameData);
          }}
        >
          Undo Last End
        </button>
      </div>

      <section className="ScoresEntry">
        <TeamScoreboardStyles isTop={true}>{topName}</TeamScoreboardStyles>
        {topEnds}
        <div className="score-label">
          <em>score</em>
        </div>
        {scoreLabels}
        <TeamScoreboardStyles isTop={false}>{bottomName}</TeamScoreboardStyles>
        {bottomEnds}
      </section>

      <div className="button-group button-group--submit">
        <button
          className="button button--submit"
          onClick={() => {
            submitScores(gameData, setModalData);
          }}
        >
          Submit Scores
        </button>
      </div>
    </ScoresEntryStyles>
  );
};

export default ScoresEntry;
