import React from 'react';
import { Link } from 'react-router-dom';
import AddTeam from '../components/AddTeam';
import MyTeams from '../components/MyTeams';
import { useUser } from '../components/User/User';

export default function MyTeamsPage() {
  const user = useUser();

  if (!user) {
    return (
      <p>
        Please <Link to="/login">Login</Link> to access My Teams
      </p>
    );
  }
  return (
    <>
      <MyTeams teams={user.teams}></MyTeams>
      <AddTeam></AddTeam>
    </>
  );
}
