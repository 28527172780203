import React from 'react';
import { GameData, End } from '../../types';
import './Scoreboard.scss';

const DEFAULT_NUM_ENDS = 6;

const getEndLabel = (index: number) => {
  return (
    <div className="end-label" key={`end-label--${index}`}>
      {index + 1}
    </div>
  );
};

export const getScoreboardHalfEnd = (
  end: End,
  isTop: boolean,
  index: number
) => {
  const { scoringTeam, score } = end;

  let scoreDisplay: string = '-';
  if (scoringTeam) {
    if (
      (scoringTeam === 'TOP' && isTop) ||
      (scoringTeam === 'BOTTOM' && !isTop)
    ) {
      scoreDisplay = score.toString();
    }
  }
  return (
    <div
      className={`end-score ${isTop ? 'end-score--top' : 'end-score--bottom'}`}
      key={`end-score--${isTop ? 'top' : 'bottom'}--${index}`}
    >
      {scoreDisplay}
    </div>
  );
};

const getScoreboardEnds = (ends: End[]) => {
  const scoreboardEnds: any[] = [];

  // Populate all ends w/ provided data
  ends.forEach((end: End, index: number) => {
    scoreboardEnds.push(getEndLabel(index));
    scoreboardEnds.push(getScoreboardHalfEnd(end, true, index));
    scoreboardEnds.push(getScoreboardHalfEnd(end, false, index));
  });

  // Fill in any empty ends if necessary
  if (ends.length < DEFAULT_NUM_ENDS) {
    const emptyEnd: End = {
      isBlank: true,
      score: 0,
      scoringTeam: 'TOP',
    };

    for (let i = ends.length; i < DEFAULT_NUM_ENDS; i++) {
      scoreboardEnds.push(getEndLabel(i));
      scoreboardEnds.push(getScoreboardHalfEnd(emptyEnd, true, i));
      scoreboardEnds.push(getScoreboardHalfEnd(emptyEnd, false, i));
    }
  }

  // Add total column
  const initialTotals = { top: 0, bottom: 0 };
  const totals = ends.reduce((currTotals, end) => {
    const { top, bottom } = currTotals;
    const { scoringTeam, isBlank, score } = end;

    if (isBlank) {
      return currTotals;
    } else {
      return {
        top: scoringTeam === 'TOP' ? top + score : top,
        bottom: scoringTeam === 'BOTTOM' ? bottom + score : bottom,
      };
    }
  }, initialTotals);

  scoreboardEnds.push(
    <div className="end-label end-label--total" key="end-label--total">
      Total
    </div>
  );
  scoreboardEnds.push(
    <div
      className="end-score end-score--top end-score--total"
      key="end-score--top--total"
    >
      {totals.top}
    </div>
  );

  scoreboardEnds.push(
    <div
      className="end-score end-score--bottom end-score--total"
      key="end-score--bottom--total"
    >
      {totals.bottom}
    </div>
  );

  return scoreboardEnds;
};

/**
 *
 * @param prop
 */
const Scoreboard: React.FC<{
  gameData: GameData;
  consistentWidth: Boolean;
}> = (prop) => {
  const { gameData, consistentWidth } = prop;
  const { ends, topTeam, bottomTeam } = gameData;

  return (
    <section
      className={`Scoreboard ${
        consistentWidth && 'Scoreboard--consistent-width'
      }`}
    >
      <div className="team team--empty">
        <em>ends</em>
      </div>
      <div className="team team--top">{topTeam.name}</div>

      <div className="team team--bottom">{bottomTeam.name}</div>
      {getScoreboardEnds(ends)}
    </section>
  );
};

export default Scoreboard;
