import React from 'react';
import { GameData, ScheduleData } from '../../types';
import { getSortedGamesByWeek } from '../HistoricalScores/HistoricalScores';
import './GameSelector.scss';

const GameSelector: React.FC<{
  scheduleData: ScheduleData;
  onGameChange: any;
  selectedWeekIndex: number;
  selectedGameIndex: number;
}> = (prop) => {
  const { scheduleData, onGameChange, selectedWeekIndex, selectedGameIndex } =
    prop;

  const weeks: JSX.Element[] = [];
  const games: JSX.Element[] = [];

  const weekChangeHandler = (event: any) => {
    const weekIndex = event.target.value;
    onGameChange(weekIndex, 0);
  };

  const gameChangeHandler = (event: any) => {
    const gameIndex = event.target.value;
    onGameChange(selectedWeekIndex, gameIndex);
  };

  const gamesByWeeks = getSortedGamesByWeek(scheduleData);

  gamesByWeeks.forEach((_games, weekIndex) => {
    weeks.push(
      <option key={`week-${weekIndex}`} value={weekIndex}>
        Week {weekIndex + 1}
      </option>
    );
  });

  gamesByWeeks[selectedWeekIndex].forEach((game: GameData, index: number) => {
    const { sheet, topTeam, bottomTeam } = game;
    games.push(
      <option key={`game-${index}`} value={index}>
        Sheet {sheet}: {topTeam.name} vs {bottomTeam.name}
      </option>
    );
  });

  return (
    <section className="GameSelector">
      <p>Select your game</p>
      <select onChange={weekChangeHandler} value={selectedWeekIndex}>
        {weeks}
      </select>
      <br></br>
      <select onChange={gameChangeHandler} value={selectedGameIndex}>
        {games}
      </select>
    </section>
  );
};

export default GameSelector;
