import { gql, useQuery } from '@apollo/client';
import React from 'react';
import './LeagueSelector.scss';

export const ALL_LEAGUES_QUERY = gql`
  query ALL_LEAGUES_QUERY {
    allLeagues(where: { isTest: false }, sortBy: leagueStart_DESC) {
      id
      name
      slug
      isActive
    }
  }
`;

const LeagueSelector: React.FC<{
  onChange: any;
  selectedLeague: string;
  showAll: boolean;
}> = ({ onChange, selectedLeague, showAll }) => {
  const { data, loading, error } = useQuery(ALL_LEAGUES_QUERY);

  if (loading) return <p>Loading...</p>;
  if (error) return <h2>Error loading all leagues: {error.message}</h2>;

  const leagueChangeHandler = (event: any) => {
    const leagueId = event.target.value;
    onChange(leagueId);
  };

  return (
    <section className="LeagueSelector">
      <span>League: </span>
      <select onChange={leagueChangeHandler} value={selectedLeague}>
        {data.allLeagues
          .filter((league: any) => {
            return showAll || league.isActive; // Only show active leagues
          })
          .map((league: any) => {
            return (
              <option key={league.id} value={league.slug}>
                {league.name}
              </option>
            );
          })}
      </select>
    </section>
  );
};

export default LeagueSelector;
