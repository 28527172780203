const DAY_OF_WEEK = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

export default function getDayOfWeek(date: Date): string {
  return DAY_OF_WEEK[date.getDay()];
}

export function getDayOfWeekShort(date: Date): string {
  return getDayOfWeek(date).substr(0, 3);
}
