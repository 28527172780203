import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { UserTeam } from './User/User';

const sortByLeagueStart = (teamA: UserTeam, teamB: UserTeam): number => {
  if (teamA.league.leagueStart < teamB.league.leagueStart) {
    return 1;
  }
  if (teamA.league.leagueStart > teamB.league.leagueStart) {
    return -1;
  }
  return 0;
};

const TeamTablesStyles = styled.table`
  text-align: left;
  min-width: 320px;
  td,
  th {
    padding: 5px;
  }
`;

const MyTeamsGrid = ({ teams }: { teams: UserTeam[] }) => {
  if (!teams.length) {
    return null;
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <TeamTablesStyles>
        <thead>
          <tr>
            <th>Name</th>
            <th>League</th>
            <th>Active</th>
            {/* <th>Record</th> */}
            {/* <th>Last Game</th> */}
            {/* <th>Next Game</th> */}
          </tr>
        </thead>
        <tbody>
          {teams.map((team) => {
            return (
              <tr key={team.id}>
                <td>
                  <Link to={`/team/${team.slug}`}>{team.name}</Link>
                </td>
                <td>{team.league.name}</td>
                <td>{team.league.isActive ? 'Y' : 'N'}</td>
              </tr>
            );
          })}
        </tbody>
      </TeamTablesStyles>
    </div>
  );
};

export default function MyTeams({ teams }: { teams: UserTeam[] }) {
  const teamsCopy = [...teams].sort(sortByLeagueStart);

  return (
    <>
      <h1>My Teams</h1>
      <MyTeamsGrid teams={teamsCopy}></MyTeamsGrid>
    </>
  );
}
