import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import React from 'react';
import { useParams } from 'react-router';
import Error from '../components/Error';
import ScoresEntry from '../components/ScoresEntry/ScoresEntry';

export const GAME_BY_ID_QUERY = gql`
  query GAME_BY_ID_QUERY($gameId: ID!) {
    Game(where: { id: $gameId }) {
      id
      sheet
      slug
      week {
        number
        league {
          name
        }
      }
      topTeam {
        id
        slug
        name
      }
      bottomTeam {
        id
        slug
        name
      }
      ends {
        number
        scoringTeam
        score
      }
    }
  }
`;

export default function SubmitGamePage() {
  const { gameId }: { gameId: string } = useParams();

  const { data, loading, error } = useQuery(GAME_BY_ID_QUERY, {
    variables: {
      gameId,
    },
  });

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <Error error={error}></Error>;
  }

  const { Game: game } = data;

  return (
    <>
      <h1>Submit scores</h1>
      <p>
        {game.week.league.name} Week {game.week.number} Sheet {game.sheet}
      </p>
      <ScoresEntry game={game} />
    </>
  );
}
