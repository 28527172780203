import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import SubstitutionTable from '../components/SubstitutionTable';
import { Substitute } from '../types';

const DisclaimerStyles = styled.p`
  text-align: left;
  max-width: var(--table-max-width);
  margin: 0 auto;
  color: blue;
`;

export const ALL_SUBS_QUERY = gql`
  query ALL_SUBS_QUERY {
    allSubstitutions {
      id
      team {
        id
        name
      }
      game {
        id
        sheet
        week {
          date
        }
      }
      spot
      subName
    }
  }
`;

/**
 * Sort the substitutions by the week they are played
 * then by team name, then by spot
 * @param a Substitute object
 * @param b Substitute object
 * @returns
 */
const sortByWeekDate = (a: Substitute, b: Substitute) => {
  const aDate = a.game.week.date;
  const bDate = b.game.week.date;

  if (aDate < bDate) {
    return -1;
  } else if (aDate > bDate) {
    return 1;
  } else {
    const aTeam = a.team.name;
    const bTeam = b.team.name;

    if (aTeam < bTeam) {
      return -1;
    } else if (aTeam > bTeam) {
      return 1;
    } else {
      return a.spot - b.spot;
    }
  }
};

export default function SubstitutionsPage() {
  const { data, loading, error } = useQuery(ALL_SUBS_QUERY);

  if (loading) return <p>Loading...</p>;
  if (error) return <h2>Error loading all subs: {error.message}</h2>;

  const NOW = new Date();
  const availableSubs: Substitute[] = [];
  const filledSubs: Substitute[] = [];
  const pastSubs: Substitute[] = [];

  data.allSubstitutions.forEach((sub: Substitute) => {
    const { game, subName } = sub;
    const { date } = game.week;

    if (date > NOW.toISOString()) {
      if (!subName) {
        availableSubs.push(sub);
      } else {
        filledSubs.push(sub);
      }
    } else {
      pastSubs.push(sub);
    }
  });

  availableSubs.sort(sortByWeekDate);
  filledSubs.sort(sortByWeekDate);
  pastSubs.sort((a, b) => sortByWeekDate(b, a)); // inverse sort

  return (
    <>
      <h1>Substitutions</h1>
      <DisclaimerStyles>
        <em>
          <strong>
            Substitutions are only available for existing league members or
            current "associate members"
          </strong>
          . To learn more about how to become an associate member, please visit
          the{' '}
          <a href="https://www.eventbrite.com/e/brooklyn-lakeside-curling-club-2021-22-associate-membership-registration-211243353557">
            associate membership sign up
          </a>{' '}
          page.
        </em>
      </DisclaimerStyles>
      <Link to="/request-substitution">Request a sub</Link>

      <h2>Available Subs</h2>
      <SubstitutionTable subs={availableSubs} />
      <h2>Upcoming Games - Filled Subs</h2>
      <SubstitutionTable subs={filledSubs} />
      <h2>Past Sub Requests</h2>
      <SubstitutionTable subs={pastSubs} isPast={true} />
    </>
  );
}
