import React from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import blccBanner from './BLCC-banner.png';
import Standings from '../Standings/Standings';
import HistoricalScores from '../HistoricalScores/HistoricalScores';
import ScoresEntryPage from '../../pages/ScoresEntryPage';
import LoginPage from '../../pages/LoginPage';
import { useUser } from '../User/User';

import './App.scss';
import SignOut from '../SignOut';
import MyTeamsPage from '../../pages/MyTeamsPage';
import SingleTeamPage from '../../pages/SingleTeamPage';
import SubmitGamePage from '../../pages/SubmitGamePage';
import styled from 'styled-components';
import SubstitutionsPage from '../../pages/SubstitutionsPage';
import RequestSubstitutionPage from '../../pages/RequestSubstitutionPage';

const ReturnToLeagueBannerStyles = styled.a`
  width: 100%;
  height: 2rem;
  background-color: #f5f5f5;
  font-size: 1.2rem;
  padding-top: 0.5rem;
`;

const App: React.FC = () => {
  const query = new URLSearchParams(window.location.search);
  const isEmbed = query.has('embed');
  const user = useUser();

  return (
    <Router>
      <div className="App">
        {!isEmbed && (
          <header className="App-header">
            <ReturnToLeagueBannerStyles href="https://www.brooklyncurling.org/">
              {'\u23CE'} Return to brooklyncurling.org
            </ReturnToLeagueBannerStyles>
            <a href="https://www.brooklyncurling.org/">
              <img
                src={blccBanner}
                className="App-logo"
                alt="Brooklyn Lakeside Curling Club Logo"
              />
            </a>
            <nav>
              {user && (
                <>
                  <Link to="/">Scores Entry</Link>
                  <span> | </span>
                </>
              )}
              <Link to="/standings">Standings</Link>
              <span> | </span>
              <Link to="/historicalScores">Historical Scores</Link>
              {user ? (
                <>
                  <span> | </span>
                  <Link to="/my-teams">My Teams</Link>
                  <span> | </span>
                  <Link to="/substitutions">Subs</Link>
                  <span> | </span>
                  <SignOut />
                </>
              ) : (
                <>
                  {/* <span> | </span>
                  <Link to='/login'>Login</Link> */}
                </>
              )}
            </nav>
          </header>
        )}

        <Switch>
          <Route path="/standings/:leagueId?" children={<Standings />}></Route>
          <Route
            path="/historicalScores/:leagueId?/:weekIndex?"
            children={<HistoricalScores />}
          ></Route>
          <Route path="/login" children={<LoginPage />}></Route>
          <Route path="/my-teams" children={<MyTeamsPage />}></Route>
          <Route
            path="/request-substitution"
            children={<RequestSubstitutionPage />}
          ></Route>
          <Route path="/substitutions" children={<SubstitutionsPage />}></Route>
          <Route path="/team/:teamSlug" children={<SingleTeamPage />}></Route>
          <Route
            path="/submit-game/:gameId"
            children={<SubmitGamePage />}
          ></Route>
          <Route
            path="/:leagueId?/:weekIndex?/:gameIndex?"
            children={<ScoresEntryPage />}
          ></Route>
        </Switch>
      </div>
    </Router>
  );
};

export default App;
