import React from 'react';
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import { CURRENT_USER_QUERY } from './User/User';
import styled from 'styled-components';

const SIGN_OUT_MUTATION = gql`
  mutation {
    endSession
  }
`;

const SignOutStyles = styled.button`
  background: none;
  border: none;
  padding: 0;
  color: #db2424;
  text-decoration: underline;
  cursor: pointer;
`;

export default function SignOut() {
  const [signOut] = useMutation(SIGN_OUT_MUTATION, {
    refetchQueries: [{ query: CURRENT_USER_QUERY }],
  });

  const signOutHandler: React.MouseEventHandler = () => {
    signOut();
  };

  return (
    <SignOutStyles type="button" onClick={signOutHandler}>
      Sign out
    </SignOutStyles>
  );
}
