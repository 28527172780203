import styled, { createGlobalStyle, css } from 'styled-components';

export const scoresRedColor = '#f12d2d';
export const scoresYellowColor = '#ffcc00';

const GlobalStyles = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Roboto+Slab&display=swap');

  // variables
  :root {
    --table-max-width: 750px;
    --scores-yellow:${scoresYellowColor};
    --scores-red: ${scoresRedColor};
    --row-height: 50px;
  }

  // Common styles
  body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    button {
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    background-color: #77d0e8;
    color: black;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
`;

export const CenterVerticalAndHorizontal = css`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const BackGroundStripeColor = (color: string) => {
  return css`
    background: linear-gradient(
      transparent 30%,
      ${color} 30%,
      ${color} 70%,
      transparent 70%,
      transparent 100%
    );
  `;
};

interface TeamScoreboardStylesProps {
  isTop: boolean;
}

export const TeamScoreboardStyles = styled('div')<TeamScoreboardStylesProps>`
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  line-height: var(--row-height);

  ${(props: any) => {
    return props.isTop
      ? BackGroundStripeColor(scoresRedColor)
      : BackGroundStripeColor(scoresYellowColor);
  }}
`;

export default GlobalStyles;
