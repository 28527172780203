import styled from 'styled-components';
import {
  BackGroundStripeColor,
  CenterVerticalAndHorizontal,
  scoresRedColor,
  scoresYellowColor,
} from './GlobalStyles';

const borderColor = 'white';
const border = `1px solid ${borderColor}`;
const scoreEntryBoxWidthXS = '30px';
const scoreEntryBoxWidthSM = '38px';

const ScoresEntryStyles = styled.div`
  .ScoresEntry {
    display: grid;
    margin: 0 auto;

    grid-template-columns: 1fr repeat(11, ${scoreEntryBoxWidthXS});
    grid-template-rows: repeat(3, var(--row-height));
    border-top: ${border};
    border-right: ${border};

    & > div {
      font-family: 'Roboto Slab', serif;
      border-left: ${border};
      border-bottom: ${border};
    }

    max-width: var(--table-max-width);
  }

  .switch-teams-button {
    width: 100%;
    height: 100%;
  }

  .score-entry {
    cursor: pointer;
    ${CenterVerticalAndHorizontal}

    &--top {
      ${BackGroundStripeColor(scoresRedColor)}
    }

    &--bottom {
      ${BackGroundStripeColor(scoresYellowColor)}
    }
  }

  .score-label {
    ${CenterVerticalAndHorizontal}
  }

  .button-group {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .button {
    font-size: 17px;
    border-radius: 15px;
  }

  .button {
    margin-right: 15px;

    &:last-of-type {
      margin-right: 0;
    }
  }

  @media (min-width: 568px) {
    .ScoresEntry {
      grid-template-columns: 1fr repeat(11, ${scoreEntryBoxWidthSM});
    }
  }
`;

export default ScoresEntryStyles;
