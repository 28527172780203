import { useEffect, useState } from 'react';

export default function useForm(initial: any) {
  // create a state for our inputs
  const [inputs, setInputs] = useState(initial);
  const initialValues = Object.values(initial).reduce((tally, input: any) => {
    return `${tally} ${input.value.toString()}`;
  }, '');

  useEffect(() => {
    // this runs when things we watch change
    setInputs(initial);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues]);

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    let { value, name, type } = e.target;

    let newValue: string | number | File = value;
    if (type === 'number') {
      newValue = parseInt(value);
    }
    if (type === 'file' && e.target.files) {
      newValue = e.target.files[0];
    }
    setInputs({
      // copy existing state
      ...inputs,
      [name]: {
        type,
        value: newValue,
      },
    });
  };

  function resetForm() {
    setInputs(initial);
  }

  function clearForm() {
    Object.keys(inputs).forEach((inputKey) => {
      inputs[inputKey].value = '';
    });
    setInputs(inputs);
  }

  // Return the things we want to surface

  return {
    inputs,
    handleChange,
    resetForm,
    clearForm,
  };
}
