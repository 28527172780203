import { useMutation, useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import React, { ChangeEvent, FormEventHandler, useRef, useState } from 'react';
import TeamSelector from '../components/App/TeamSelector';
import GameSelectorByTeam from '../components/GameSelectorByTeam';
import LeagueSelector from '../components/LeagueSelector/LeagueSelector';
import { Team } from '../types';
import useForm from '../lib/useForm';
import Error from '../components/Error';
import { ALL_SUBS_QUERY } from './SubstitutionsPage';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { FieldSetStyles } from '../styles/FieldSet';

const DEFAULT_LEAGUE = process.env.REACT_APP_DEFAULT_LEAGUE || '2021-w-w';

const TEAMS_BY_LEAGUE_QUERY = gql`
  query TEAMS_BY_LEAGUE_QUERY($leagueSlug: String!) {
    allTeams(where: { league: { slug: $leagueSlug } }) {
      id
      name
      league {
        id
      }
    }
  }
`;

const CREATE_SUBSTITUTIONS_MUTATION = gql`
  mutation CREATE_SUBSTITUTIONS_MUTATION(
    $substitutions: [SubstitutionsCreateInput!]!
  ) {
    createSubstitutions(data: $substitutions) {
      id
    }
  }
`;

export default function RequestSubstitutionPage() {
  //TODO: Instead of using useRef for gameSelect,
  //try cascading requests via useReducer
  const gameSelectRef = useRef<HTMLSelectElement>(null);
  const [selectedLeagueSlug, setSelectedLeagueSlug] = useState(DEFAULT_LEAGUE);
  const [selectedTeamIndex, setSelectedTeamIndex] = useState(0);
  const [numCurlersNeeded, setNumCurlersNeeded] = useState(1);
  const history = useHistory();

  const [
    createSubstitutions,
    { loading: createSubsLoading, error: createSubsError },
  ] = useMutation(CREATE_SUBSTITUTIONS_MUTATION, {
    refetchQueries: [{ query: ALL_SUBS_QUERY }],
  });

  const { data, loading, error } = useQuery(TEAMS_BY_LEAGUE_QUERY, {
    variables: { leagueSlug: selectedLeagueSlug },
  });

  const { inputs, handleChange } = useForm({
    myEmail: {
      type: 'email',
      value: '',
    },
  });

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error getting teams: {error.message}</p>;

  const teams = data.allTeams;
  const selectedTeam: Team = teams[selectedTeamIndex];

  const onLeagueChange = (leagueId: string) => {
    setSelectedLeagueSlug(leagueId);
    setSelectedTeamIndex(0);
  };

  const onTeamChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setSelectedTeamIndex(event.target.selectedIndex);
  };

  const submitSubRequest: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();

    const substitutions = [];
    for (let i = 0; i < numCurlersNeeded; i++) {
      const { myEmail } = inputs;
      const gameId = gameSelectRef.current?.value;
      const teamId = selectedTeam.id;
      const leagueId = selectedTeam?.league?.id;
      substitutions.push({
        data: {
          league: {
            connect: {
              id: leagueId,
            },
          },
          game: {
            connect: {
              id: gameId,
            },
          },
          team: { connect: { id: teamId } },
          spot: i + 1,
          requestorEmail: myEmail.value,
        },
      });
    }

    await createSubstitutions({
      variables: {
        substitutions,
      },
    });

    //assumes success
    history.push('/substitutions');
  };

  return (
    <form onSubmit={submitSubRequest}>
      <h1>Request Substitution(s)</h1>
      <p>
        <Link to="/substitutions">View sub requests</Link>
      </p>
      {createSubsError && <Error error={createSubsError} />}
      <FieldSetStyles
        disabled={createSubsLoading}
        aria-busy={createSubsLoading}
      >
        <LeagueSelector
          onChange={onLeagueChange}
          selectedLeague={selectedLeagueSlug}
          showAll={false}
        ></LeagueSelector>
        <TeamSelector teams={teams} onChange={onTeamChange}></TeamSelector>
        <GameSelectorByTeam
          teamId={selectedTeam.id}
          gameSelectRef={gameSelectRef}
        ></GameSelectorByTeam>
        <p>
          <span>Curlers Needed: </span>
          <select
            defaultValue={`${numCurlersNeeded}`}
            onChange={(e) => {
              setNumCurlersNeeded(parseInt(e.target.value));
            }}
          >
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4 (full team)</option>
          </select>
        </p>
        <p>
          <label htmlFor="myEmail">
            Your email (no emails sent at this time):{' '}
            <input
              required
              type={inputs.myEmail.type}
              name="myEmail"
              placeholder="user@domain.com"
              value={inputs.myEmail.value}
              onChange={handleChange}
            />
          </label>
        </p>
        <button>Submit</button>
      </FieldSetStyles>
    </form>
  );
}
