import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import React from 'react';
import { useParams } from 'react-router';
import Error from '../components/Error';
import TeamSchedule from '../components/TeamSchedule';

const SINGLE_TEAM_PAGE_QUERY = gql`
  query SINGLE_TEAM_PAGE_QUERY($teamSlug: String!) {
    allTeams(where: { slug: $teamSlug }) {
      name
      id
      league {
        id
        name
      }
    }
  }
`;

export default function SingleTeamPage() {
  const { teamSlug }: { teamSlug: string } = useParams();

  const { data, loading, error } = useQuery(SINGLE_TEAM_PAGE_QUERY, {
    variables: {
      teamSlug,
    },
  });

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <Error error={error} />;
  }

  if (data.allTeams.length === 0) {
    return <p>Unknown team slug: '{teamSlug}'</p>;
  }

  const team = data.allTeams[0];

  return (
    <>
      <h2>
        {team.name} - <em>{team.league.name}</em>
      </h2>
      <TeamSchedule teamId={team.id}></TeamSchedule>
      {/* <p> Team Roster</p> */}
    </>
  );
}
