import React, { ChangeEventHandler } from 'react';
import { Team } from '../../types';

export default function TeamSelector({
  teams,
  onChange,
}: {
  teams: Team[];
  onChange: ChangeEventHandler<HTMLSelectElement>;
}) {
  return (
    <p>
      <span>Team: </span>
      <select onChange={onChange}>
        {teams.map((team: Team) => {
          return (
            <option key={team.id} value={team.id}>
              {team.name}
            </option>
          );
        })}
      </select>
    </p>
  );
}
