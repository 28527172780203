import React from 'react';
import { gql, useQuery } from '@apollo/client';

interface UserLeague {
  id: string;
  slug: string;
  name: string;
  leagueStart: string;
  isActive: boolean;
}

export interface UserTeam {
  name: string;
  slug: string;
  id: string;
  league: UserLeague;
}

export interface User {
  id: string;
  email: string;
  name: string;
  teams: UserTeam[];
}

export const CURRENT_USER_QUERY = gql`
  query CURRENT_USER_QUERY {
    authenticatedItem {
      ... on User {
        id
        email
        name
        teams {
          name
          slug
          id
          league {
            id
            slug
            name
            leagueStart
            isActive
          }
        }
      }
    }
  }
`;

export function useUser(): User | null {
  const { data } = useQuery(CURRENT_USER_QUERY);

  return data && data.authenticatedItem;
}

const User: React.FC = () => {
  return null;
};
export default User;
