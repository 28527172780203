import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import React from 'react';
import { useHistory } from 'react-router';
import useForm from '../lib/useForm';
import FormStyles from '../styles/Form';
import Error from './Error';
import { CURRENT_USER_QUERY } from './User/User';

const SIGNIN_MUTATION = gql`
  mutation SIGNIN_MUTATION($email: String!, $password: String!) {
    authenticateUserWithPassword(email: $email, password: $password) {
      ... on UserAuthenticationWithPasswordSuccess {
        item {
          id
          email
        }
      }
      ... on UserAuthenticationWithPasswordFailure {
        message
      }
    }
  }
`;

export default function SignIn() {
  const history = useHistory();
  const { inputs, handleChange, resetForm } = useForm({
    email: {
      type: 'email',
      value: '',
    },
    password: {
      type: 'password',
      value: '',
    },
  });

  let [signIn, { data, loading, error }] = useMutation(SIGNIN_MUTATION, {
    variables: {
      email: inputs.email.value,
      password: inputs.password.value,
    },
    // refetch the currently logged in user
    refetchQueries: [CURRENT_USER_QUERY],
  });

  const handleSubmit: React.FormEventHandler = async (e) => {
    e.preventDefault();
    await signIn().catch(console.error);
    resetForm();
  };

  console.log('sign in!');
  console.log({ data, loading, error });

  const signInError =
    error ||
    data?.authenticateUserWithPassword.__typename ===
      'UserAuthenticationWithPasswordFailure'
      ? new RangeError(data?.authenticateUserWithPassword?.message)
      : undefined;

  if (data && !signInError) {
    // If login succeeds
    history.push('/my-teams');
  }

  return (
    <FormStyles method="POST" onSubmit={handleSubmit}>
      <h2>Sign Into Your Account</h2>
      {signInError && <Error error={signInError} />}
      <fieldset disabled={loading} aria-busy={loading}>
        <label htmlFor="email">
          Email
          <input
            required
            type={inputs.email.type}
            name="email"
            placeholder="Your Email Address"
            autoComplete="email"
            value={inputs.email.value}
            onChange={handleChange}
          />
        </label>
        <label htmlFor="password">
          Password
          <input
            required
            type={inputs.password.type}
            name="password"
            placeholder="Password"
            autoComplete="password"
            value={inputs.password.value}
            onChange={handleChange}
          />
        </label>
        <button type="submit">Sign In</button>
      </fieldset>
    </FormStyles>
  );
}
