import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import AlertModal from '../AlertModal/AlertModal';
import LeagueSelector from '../LeagueSelector/LeagueSelector';
import StandingsTable from '../StandingsTable/StandingsTable';
import { StandingsData, ModalData } from '../../types';
import './Standings.scss';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';

const DEFAULT_LEAGUE = process.env.REACT_APP_DEFAULT_LEAGUE || '2021-w-w';
const LOADING_MODAL_DATA: ModalData = {
  display: true,
  text: 'Loading Data',
  mode: 'loading',
};

export const GET_STANDINGS_QUERY = gql`
  query GET_STANDINGS_QUERY($leagueSlug: String!) {
    getStandings(leagueSlug: $leagueSlug)
  }
`;

const Standings: React.FC = () => {
  const history = useHistory();
  const { leagueId = DEFAULT_LEAGUE }: { leagueId?: string } = useParams();
  const [selectedLeague, setSelectedLeague] = useState(leagueId);

  const standingsTableDataArray = [];

  const { data, loading, error } = useQuery(GET_STANDINGS_QUERY, {
    variables: {
      leagueSlug: leagueId,
    },
  });

  if (loading) {
    return (
      <AlertModal
        key="standings-loading"
        modalData={LOADING_MODAL_DATA}
      ></AlertModal>
    );
  }

  if (error) {
    return (
      <AlertModal
        key="standings-error"
        modalData={{
          display: true,
          text: `Error loading standings: ${error.message}`,
          mode: 'gameSubmit',
        }}
      ></AlertModal>
    );
  }

  const standingsData: StandingsData = data.getStandings;

  if (Object.keys(standingsData.divisions).length) {
    Object.keys(standingsData.divisions).forEach((division) => {
      standingsTableDataArray.push({
        division,
        teams: standingsData.divisions[division],
      });
    });
  } else {
    standingsTableDataArray.push({
      division: '',
      teams: standingsData.teams,
    });
  }

  const standingsTables = standingsTableDataArray.map(
    (standingsTableData, index) => {
      return (
        <div key={`standings-table--${index}`}>
          <h4>
            {standingsTableData.division &&
              `${standingsTableData.division} Division`}
          </h4>
          <StandingsTable teams={standingsTableData.teams}></StandingsTable>
        </div>
      );
    }
  );
  const onLeagueChange = (leagueId: string) => {
    history.push(`/standings/${leagueId}`);
    setSelectedLeague(leagueId);
  };

  return (
    <div className="standings-page">
      <LeagueSelector
        selectedLeague={selectedLeague}
        onChange={onLeagueChange}
        showAll={true}
      ></LeagueSelector>
      {standingsTables}
    </div>
  );
};

export default Standings;
