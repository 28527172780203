import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import React from 'react';
import useForm from '../lib/useForm';
import FormStyles from '../styles/Form';
import Error from './Error';

const SIGNUP_MUTATION = gql`
  mutation SIGNUP_MUTATION(
    $email: String!
    $name: String!
    $password: String!
  ) {
    createUser(data: { email: $email, name: $name, password: $password }) {
      id
      email
      name
    }
  }
`;

export default function SignUp() {
  const { inputs, handleChange, resetForm } = useForm({
    name: {
      type: 'name',
      value: '',
    },
    email: {
      type: 'email',
      value: '',
    },
    password: {
      type: 'password',
      value: '',
    },
  });

  const [signUp, { loading, error }] = useMutation(SIGNUP_MUTATION, {
    variables: {
      name: inputs.name.value,
      email: inputs.email.value,
      password: inputs.password.value,
    },
  });

  const handleSubmit: React.FormEventHandler = async (e) => {
    e.preventDefault();
    await signUp().catch(console.error);
    resetForm();
  };

  return (
    <FormStyles method="POST" onSubmit={handleSubmit}>
      <h2>Sign Up for an Account</h2>
      {error && <Error error={error} />}
      <fieldset disabled={loading} aria-busy={loading}>
        <label htmlFor="name">
          Name
          <input
            required
            type={inputs.name.value}
            name="name"
            placeholder="Your Name"
            autoComplete="name"
            value={inputs.name.value}
            onChange={handleChange}
          />
        </label>
        <label htmlFor="email">
          Email
          <input
            required
            type={inputs.email.value}
            name="email"
            placeholder="Your Email Address"
            autoComplete="email"
            value={inputs.email.value}
            onChange={handleChange}
          />
        </label>
        <label htmlFor="password">
          Password
          <input
            required
            type={inputs.password.type}
            name="password"
            placeholder="Password"
            autoComplete="password"
            value={inputs.password.value}
            onChange={handleChange}
          />
        </label>
        <button type="submit">Sign Up</button>
      </fieldset>
    </FormStyles>
  );
}
