import React from 'react';

import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';
import { Substitute } from '../types';
import useForm from '../lib/useForm';
import { FieldSetStyles } from '../styles/FieldSet';
import { ALL_SUBS_QUERY } from '../pages/SubstitutionsPage';
import { getDayOfWeekShort } from '../lib/dateFunctions';

const UPDATE_SUBSTITUTION_MUTATION = gql`
  mutation UPDATE_SUBSTITUTION_MUTATION(
    $subId: ID!
    $subName: String!
    $subEmail: String!
  ) {
    updateSubstitution(
      id: $subId
      data: { subName: $subName, subEmail: $subEmail }
    ) {
      id
    }
  }
`;

export default function SubstitutionSignUpModal({
  substitutionData,
  onCancel,
}: {
  substitutionData: Substitute | null;
  onCancel: () => void;
}) {
  const { inputs, handleChange } = useForm({
    subName: {
      type: 'text',
      value: '',
    },
    subEmail: {
      type: 'email',
      value: '',
    },
  });

  const [updateSubstitution, { data, loading, error }] = useMutation(
    UPDATE_SUBSTITUTION_MUTATION,
    {
      refetchQueries: [
        {
          query: ALL_SUBS_QUERY,
        },
      ],
    }
  );

  if (!substitutionData) {
    return null;
  }

  const { game, team, id } = substitutionData;
  const { name: teamName } = team;
  const { week } = game;
  const { date } = week;
  const dateObj = new Date(date);

  const headerMessage = data
    ? 'Substitution updated!'
    : error
    ? `Error, please email adam.pirani@gmail.com with this message: '${error.message}'`
    : loading
    ? 'Submitting substitution...'
    : `Sub for ${teamName} on ${getDayOfWeekShort(
        dateObj
      )} ${dateObj.toLocaleDateString()} at ${dateObj.toLocaleTimeString()}`;

  return (
    <section className="AlertModal">
      <div className="alert-card">
        <h3>{headerMessage}</h3>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            updateSubstitution({
              variables: {
                subId: id,
                subName: inputs.subName.value,
                subEmail: inputs.subEmail.value,
              },
            });
          }}
        >
          {loading ? (
            <p className="loading-animation">
              <span className="loading-animation__dot">·</span>
              <span className="loading-animation__dot">·</span>
              <span className="loading-animation__dot">·</span>
            </p>
          ) : data ? (
            <button
              className="button"
              onClick={() => {
                onCancel();
              }}
            >
              Close
            </button>
          ) : (
            <FieldSetStyles>
              <label htmlFor="subName">
                Your full name:{' '}
                <input
                  required
                  type={inputs.subName.type}
                  name="subName"
                  placeholder="Randy Ferby"
                  value={inputs.subName.value}
                  onChange={handleChange}
                />
              </label>
              <br />
              <label htmlFor="subEmail">
                Your email (no emails sent at this time):{' '}
                <input
                  required
                  type={inputs.subEmail.type}
                  name="subEmail"
                  placeholder="name@domain.com"
                  value={inputs.subEmail.value}
                  onChange={handleChange}
                />
              </label>

              <br />
              <br />
              <div className="button-group">
                <button type="submit" className="button">
                  Submit
                </button>
                <button
                  type="button"
                  className="button"
                  onClick={() => {
                    onCancel();
                  }}
                >
                  Cancel
                </button>
              </div>
            </FieldSetStyles>
          )}
        </form>
      </div>
    </section>
  );
}
