import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import LeagueSelector from '../components/LeagueSelector/LeagueSelector';
import GameSelector from '../components/GameSelector/GameSelector';
import EntryInstructions from '../components/EntryInstructions/EntryInstructions';
import AlertModal from '../components/AlertModal/AlertModal';
import {
  getSortedGamesByWeek,
  LEAGUE_SCHEDULE_QUERY,
} from '../components/HistoricalScores/HistoricalScores';
import { GameData, ModalData } from '../types';
import { useQuery } from '@apollo/client';
import ScoresEntry from '../components/ScoresEntry/ScoresEntry';

const UNSELECTED_INDEX = -1;
const DEFAULT_LEAGUE = process.env.REACT_APP_DEFAULT_LEAGUE || '2021-w-w';
const LOADING_MODAL_DATA: ModalData = {
  display: true,
  text: 'Loading Data',
  mode: 'loading',
};

const ScoresEntryPage: React.FC = () => {
  const history = useHistory();
  const {
    leagueId = DEFAULT_LEAGUE,
    weekIndex = `${UNSELECTED_INDEX}`,
    gameIndex = `${UNSELECTED_INDEX}`,
  }: {
    leagueId?: string;
    weekIndex?: string;
    gameIndex?: string;
  } = useParams();

  const [selectedLeague, setSelectedLeague] = useState(leagueId);
  const [selectedWeekIndex, setSelectedWeekIndex] = useState(
    parseInt(weekIndex)
  );
  const [selectedGameIndex, setSelectedGameIndex] = useState(
    parseInt(gameIndex)
  );

  const { data, loading, error } = useQuery(LEAGUE_SCHEDULE_QUERY, {
    variables: {
      leagueSlug: selectedLeague,
    },
  });

  if (loading) {
    return (
      <AlertModal key="loading" modalData={LOADING_MODAL_DATA}></AlertModal>
    );
  }

  if (error) {
    return (
      <AlertModal
        key={error.message}
        modalData={{
          display: true,
          text: `Error loading schedule: ${error.message}`,
          mode: 'gameSubmit',
        }}
      ></AlertModal>
    );
  }

  const scheduleData = data.allLeagues[0];
  const { currentWeek } = scheduleData;

  const weekIdx =
    selectedWeekIndex === UNSELECTED_INDEX
      ? currentWeek - 1
      : selectedWeekIndex;
  const gameIdx =
    selectedGameIndex === UNSELECTED_INDEX ? 0 : selectedGameIndex;

  const gamesByWeeks = getSortedGamesByWeek(scheduleData);

  const gameData: GameData = gamesByWeeks[weekIdx][gameIdx];

  const onLeagueChange = (leagueId: string) => {
    history.push(`/${leagueId}`);

    setSelectedLeague(leagueId);
    // On league change we need to set defaults to data to clear params
    setSelectedWeekIndex(UNSELECTED_INDEX);
    setSelectedGameIndex(UNSELECTED_INDEX);
  };

  const onGameChange = (weekIndex: number, gameIndex: number) => {
    history.push(`/${leagueId}/${weekIndex}/${gameIndex}`);
    setSelectedWeekIndex(weekIndex);
    setSelectedGameIndex(gameIndex);
  };

  return (
    <div className="scores-entry-page">
      <LeagueSelector
        onChange={onLeagueChange}
        selectedLeague={selectedLeague}
        showAll={false}
      ></LeagueSelector>
      <GameSelector
        scheduleData={scheduleData}
        onGameChange={onGameChange}
        selectedGameIndex={gameIdx}
        selectedWeekIndex={weekIdx}
      ></GameSelector>
      {/* ScoresEntry requires a key so that each game re-renders new state */}
      <ScoresEntry key={gameData.slug} game={gameData}></ScoresEntry>
      <EntryInstructions></EntryInstructions>
    </div>
  );
};

export default ScoresEntryPage;
