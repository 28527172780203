import React from 'react';
import { GameSummaryTeam } from '../../types';
import './GameSummary.scss';

const GameSummary: React.FC<{
  topTeam: GameSummaryTeam;
  bottomTeam: GameSummaryTeam;
}> = (prop) => {
  const { topTeam, bottomTeam } = prop;
  return (
    <section className="GameSummary">
      <div className="team-summary">
        <div className="team-summary__team team-summary__team--top">
          {topTeam.name}
        </div>
        <div className="team-summary__score">{topTeam.score}</div>
      </div>
      <div className="team-summary">
        <div className="team-summary__team team-summary__team--bottom">
          {bottomTeam.name}
        </div>
        <div className="team-summary__score">{bottomTeam.score}</div>
      </div>
    </section>
  );
};

export default GameSummary;
