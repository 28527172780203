import React, { useState } from 'react';
import Scoreboard from '../Scoreboard/Scoreboard';
import { ModalData } from '../../types';
import './AlertModal.scss';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';
import { LEAGUE_SCHEDULE_QUERY } from '../HistoricalScores/HistoricalScores';
import { GET_STANDINGS_QUERY } from '../Standings/Standings';
import { GAME_BY_ID_QUERY } from '../../pages/SubmitGamePage';
import { GAMES_BY_TEAM_QUERY } from '../TeamSchedule';

const SUBMIT_SCORE_MUTATION = gql`
  mutation SUBMIT_SCORE_MUTATION(
    $gameId: ID!
    $gameData: JSON!
    $userName: String!
  ) {
    submitScore(gameId: $gameId, gameData: $gameData, userName: $userName) {
      id
    }
  }
`;

const AlertModal: React.FC<{
  modalData: ModalData;
  onCancel?: any;
}> = (prop) => {
  const { modalData, onCancel } = prop;
  const { text, mode, gameData }: ModalData = modalData;
  const isLoading = mode === 'loading';
  const [userName, setUserName] = useState('');

  const [submitScore, { data, loading, error }] = useMutation(
    SUBMIT_SCORE_MUTATION,
    {
      refetchQueries: [
        LEAGUE_SCHEDULE_QUERY,
        GET_STANDINGS_QUERY,
        GAME_BY_ID_QUERY,
        GAMES_BY_TEAM_QUERY,
      ],
    }
  );

  const headerMessage = data
    ? 'Scores submitted successfully'
    : error
    ? `Error, please email adam.pirani@gmail.com with this message: '${error.message}'`
    : loading
    ? 'Submitting scores...'
    : text;

  return (
    <section className="AlertModal">
      <div className="alert-card">
        <h3>{headerMessage}</h3>
        {isLoading || loading ? (
          <p className="loading-animation">
            <span className="loading-animation__dot">·</span>
            <span className="loading-animation__dot">·</span>
            <span className="loading-animation__dot">·</span>
          </p>
        ) : data ? (
          <button
            className="button"
            onClick={() => {
              onCancel();
            }}
          >
            Close
          </button>
        ) : (
          gameData && (
            <div>
              <Scoreboard
                gameData={gameData}
                consistentWidth={false}
              ></Scoreboard>
              <br></br>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  submitScore({
                    variables: {
                      gameId: gameData.id,
                      gameData,
                      userName,
                    },
                  });
                }}
              >
                <span>Enter your full name: </span>
                <input
                  required
                  onChange={(evt) => {
                    setUserName(evt.target.value);
                  }}
                ></input>
                <br></br>
                <div className="button-group">
                  <button type="submit" className="button">
                    Submit
                  </button>
                  <button
                    type="button"
                    className="button"
                    onClick={() => {
                      onCancel();
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          )
        )}
      </div>
    </section>
  );
};

export default AlertModal;
