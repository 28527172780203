import React, { useState } from 'react';
import styled from 'styled-components';
import { Substitute } from '../types';
import { getDayOfWeekShort } from '../lib/dateFunctions';
import SubstitutionSignUpModal from './SubstitutionSignUpModal';

const SubstitutionTableStyle = styled.table`
  margin: 0 auto;
  width: 100%;
  max-width: var(--table-max-width);
`;

export default function SubstitutionTable({
  subs,
  isPast = false,
}: {
  subs: Substitute[];
  isPast?: boolean;
}): JSX.Element {
  const [showModal, setShowModal] = useState(false);
  const [selectedSub, setSelectedSub] = useState<Substitute | null>(null);

  if (subs.length === 0) {
    return <p>No substitutions found</p>;
  }

  return (
    <>
      {showModal && (
        <SubstitutionSignUpModal
          substitutionData={selectedSub}
          onCancel={() => {
            setShowModal(false);
          }}
        />
      )}
      <SubstitutionTableStyle>
        <thead>
          <tr>
            <th>Date</th>
            <th>Team</th>
            <th>Sheet</th>
            <th>Spot</th>
            <th>Sub Name</th>
          </tr>
        </thead>
        <tbody>
          {subs.map((sub: Substitute) => {
            const { game, subName, id } = sub;
            const { week, sheet } = game;
            const { date } = week;
            const { spot, team } = sub;
            const { name } = team;
            const dateObj = new Date(date);

            return (
              <tr key={id}>
                <td>
                  {getDayOfWeekShort(dateObj)} {dateObj.toLocaleDateString()}{' '}
                  {dateObj.toLocaleTimeString()}
                </td>
                <td>{name}</td>
                <td>{sheet}</td>
                <td>{spot}</td>
                {subName ? (
                  <td>{subName}</td>
                ) : (
                  <td>
                    {isPast ? (
                      <em>Unfilled</em>
                    ) : (
                      <button
                        onClick={() => {
                          setSelectedSub(sub);
                          setShowModal(true);
                        }}
                      >
                        Sign Up
                      </button>
                    )}
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </SubstitutionTableStyle>
    </>
  );
}
