import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import React from 'react';
import { Link } from 'react-router-dom';
import { GameData } from '../types';
import Error from './Error';
import Scoreboard from './Scoreboard/Scoreboard';
import { User, useUser } from './User/User';

export const GAMES_BY_TEAM_QUERY = gql`
  query GAMES_BY_TEAM_QUERY($teamId: ID!) {
    allGames(
      where: {
        OR: [{ topTeam: { id: $teamId } }, { bottomTeam: { id: $teamId } }]
      }
    ) {
      id
      sheet
      topTeam {
        id
        name
      }
      bottomTeam {
        id
        name
      }
      ends {
        number
        scoringTeam
        score
      }
      weekInteger
      week {
        number
        date
      }
    }
  }
`;

/**
 *
 * @param user User from login (possibly null)
 * @param gameData Game to check if your team is in
 * @returns true if the game involves one of your teams
 */
const isMyTeamPlaying = (user: User | null, gameData: GameData): boolean => {
  if (user === null) {
    return false;
  }

  const indexOfTeam = user.teams.findIndex((team) => {
    return (
      team.id === gameData.topTeam.id || team.id === gameData.bottomTeam.id
    );
  });

  return indexOfTeam > -1;
};

export default function TeamSchedule({ teamId }: { teamId: string }) {
  const { data, loading, error } = useQuery(GAMES_BY_TEAM_QUERY, {
    variables: {
      teamId,
    },
  });

  const user = useUser();

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <Error error={error}></Error>;
  }

  return (
    <>
      <h3>Schedule </h3>
      {data.allGames.map((gameData: GameData) => {
        const isBottomTeam = teamId === gameData.bottomTeam.id;

        return (
          <div key={gameData.id}>
            <h4>Week {gameData.weekInteger}</h4>
            {gameData.ends.length ? (
              <Scoreboard
                gameData={gameData as GameData}
                consistentWidth={false}
              ></Scoreboard>
            ) : (
              <p>
                Sheet {gameData.sheet} vs.{' '}
                {isBottomTeam
                  ? gameData.topTeam.name
                  : gameData.bottomTeam.name}
                {isMyTeamPlaying(user, gameData) && (
                  <>
                    {' - '}
                    <Link to={`/submit-game/${gameData.id}`}>
                      {' '}
                      Submit Score
                    </Link>
                  </>
                )}
              </p>
              //   TODO: Add submit scores link
            )}
          </div>
        );
      })}
    </>
  );
}
