import React from 'react';
import './EntryInstructions.scss';

const EntryInstructions: React.FC = () => {
  return (
    <div className="EntryInstructions">
      <div className="EntryInstructions__container">
        <h2>FAQs</h2>
        <h3 className="EntryInstructions__heading">
          How do I change my team to/from yellow/red?
        </h3>
        <p className="EntryInstructions__paragraph">
          Click the <strong>"Swap Colors"</strong> button
        </p>
        <h3 className="EntryInstructions__heading">
          How do I work this thing?
        </h3>
        <p className="EntryInstructions__paragraph">
          This operates the same way as our manual scoreboards during the game.
        </p>
        <ol>
          <li>
            Tap (or click) in the space where you would hang the next end
            <ul>
              <li>The end number will automatically be placed in that spot</li>
              <li>
                If red scores, click the space above the number of points that
                were scored
              </li>
              <li>
                If yellow scores, click the space below the number of points
                that were scored
              </li>
              <li>A running total is available above</li>
            </ul>
          </li>
          <li>Repeat until you have completed all ends</li>
          <ul>
            <li>
              If an error occurs, click <strong>"Undo Last End"</strong> to
              remove the last end, or <strong>"Clear Ends"</strong> to restart
            </li>
          </ul>
          <li>Click Submit</li>
          <li>In the popup, verify the scoring is correct</li>
          <li>Enter your name</li>
          <li>Click 'Submit'</li>
        </ol>
        <h3 className="EntryInstructions__heading">
          How do I score a forfeit?
        </h3>
        <p className="EntryInstructions__paragraph">
          A forfeit is recorded as a 4-0 win in favor of the non-forfeiting
          team, scored as one point per end over the first four ends.
        </p>
        <h3 className="EntryInstructions__heading">Other questions</h3>
        <p className="EntryInstructions__paragraph">
          For further information on forfeits, scoring, substitutes and other
          league rules, please see the{' '}
          <a href="https://www.brooklyncurling.org/leagues.html">
            BLCC League Overview page
          </a>
          .
        </p>
      </div>
    </div>
  );
};

export default EntryInstructions;
