import React from 'react';
import styled from 'styled-components';

const ErrorStyles = styled.div`
  color: red;
  text-align: left;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-left: 5px solid red;
  padding-left: 5px;
  margin-bottom: 10px;
`;

export default function Error({ error }: { error: Error }) {
  return (
    <ErrorStyles>
      <p>Error: {error.message}</p>
      <p>
        If error persists, please contact{' '}
        <a href="mailto:adam.pirani@gmail.com">Adam Pirani</a>
      </p>
    </ErrorStyles>
  );
}
