import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import React from 'react';
import useForm from '../lib/useForm';
import FormStyles from '../styles/Form';
import Error from './Error';
import { CURRENT_USER_QUERY, useUser } from './User/User';

const ADD_TEAM_TO_USER_MUTATION = gql`
  mutation ADD_TEAM_TO_USER_MUTATION($userId: ID!, $teamId: ID!) {
    updateUser(id: $userId, data: { teams: { connect: [{ id: $teamId }] } }) {
      name
      teams {
        name
      }
    }
  }
`;

export default function AddTeam() {
  const user = useUser();

  const { inputs, handleChange, resetForm } = useForm({
    teamId: {
      type: 'string',
      value: '',
    },
  });

  const [addTeamToUser, { loading, error }] = useMutation(
    ADD_TEAM_TO_USER_MUTATION,
    {
      variables: {
        userId: user?.id,
        teamId: inputs.teamId.value,
      },
      refetchQueries: [CURRENT_USER_QUERY],
    }
  );

  const handleSubmit: React.FormEventHandler = async (e) => {
    e.preventDefault();
    await addTeamToUser().catch(console.error);
    resetForm();
  };

  return (
    <FormStyles method="POST" onSubmit={handleSubmit}>
      <h2>Add Team</h2>
      {error && <Error error={error} />}
      <fieldset disabled={loading} aria-busy={loading}>
        <label htmlFor="teamId">
          Team ID
          <input
            required
            type={inputs.teamId.type}
            name="teamId"
            placeholder="Your Team's ID from league email"
            value={inputs.teamId.value}
            onChange={handleChange}
          />
        </label>
        <button type="submit">Add</button>
      </fieldset>
    </FormStyles>
  );
}
